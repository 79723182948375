import React from "react";
import { Heading, Text, Box, Flex } from "@chakra-ui/react";

export const LoaderBasic = () => {
  return (
    <Box mt="80" bgColor="white" alignItems="center" mx="10" my="10">
      <Flex direction="column" align="center" mx="10" my="10">
        <Heading as="h2" size="xl">
          Espere un momento
        </Heading>
        <div className="ploader"></div>
        <Text alignText="center" color={"gray.500"}>
          Su información está siendo procesada, por favor espere.
          <Text fontSize="sm">Este proceso puede tomar varios minutos.</Text>
        </Text>
      </Flex>
    </Box>
  );
};
