import {
  Flex,
  Stack,
  Heading,
  Button,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Link,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import redux from "reducers/utils/Redux";
import { LoaderBasic } from "components/LoaderBasic";
import React from "react";
import { DEFAULT_TOAST_TIME } from "configuration";

function Contrato({ getContractTicket, globalEffectUpdate, validateFAD }) {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(true);
  const [contractTicket, setContractTicket] = useState("");
  const toast = useToast();

  async function sendContrato() {
    setLoading(true);
    closeModal();
    const { data, status } = await getContractTicket();
    if (status === 200) {
      setLoading(false);
      setButtonPressed(false);
      setContractTicket(data.contract_ticket);
      window.open(
        `https://mobile.firmaautografa.com/main?ticket=${data.contract_ticket}`,
        "_blank"
      );
    } else if (status === 401) {
      globalEffectUpdate();
    } else if (status === 400) {
      toast({
        position: "top",
        title: "Error al obtener el contrato",
        description: "Contacta a soporte",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (status === 500) {
      toast({
        position: "top",
        title: "Error al obtener el contrato",
        description: "Contacta a soporte",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
  }

  async function validarFirma() {
    const { data, status } = await validateFAD();

    if (status === 200) {
      if (data.status) {
        globalEffectUpdate();
      } else {
        toast({
          position: "top",
          title: "Proceso de firma no terminado",
          description: "Regresa cuando hayas firmado tu contrato",
          status: "error",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      }
    } else if (status === 400) {
      toast({
        position: "top",
        title: "Contrato inválido",
        description: "Contacta a soporte",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await validateFAD();
      if (data.status) {
        globalEffectUpdate();
      }
      setLoading(false);
    };
    fetchData();
  }, [validateFAD, globalEffectUpdate]);

  if (loading) {
    return <LoaderBasic />; // Puedes mostrar un mensaje de carga mientras se verifica la autenticación
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Firma de contrato</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Contenido del modal */}
            <Text>
              Da click en firmar contrato.
              <br />
              Cuando hayas finalizado el proceso de firma regresa a esta página.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={sendContrato}>
              Firmar contrato
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={4} w={"full"} maxW={"md"}>
          <Heading
            color={"blue.700"}
            w="100%"
            textAlign={"center"}
            fontWeight="normal"
            mb="2%"
          >
            Firma de contrato
          </Heading>

          <Text>Ya sólo tienes que firmar tu contrato</Text>
          {buttonPressed ? (
            <Button colorScheme="blue" onClick={openModal}>
              Firmar
            </Button>
          ) : (
            <>
              <Button
                onClick={validarFirma}
                colorScheme={"blue"}
                variant="outline"
              >
                Ya firmé el contrato
              </Button>
              <Link
                color={"blue"}
                target="_blank"
                href={`https://mobile.firmaautografa.com/main?ticket=${contractTicket}`}
              >
                Si no se abrió la ventana de firma da click aquí
              </Link>
            </>
          )}
        </Stack>
      </Flex>
    </>
  );
}

export default redux(Contrato);
