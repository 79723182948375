import { Flex, Heading, Stack, useToast, Button } from "@chakra-ui/react";
import { FormFieldValidation } from "./FormFieldValidation";
import { useState } from "react";
import { ButtonValidations } from "./ButtonValidations";
import CountdownLoader from "components/CountdownLoader";
import React from "react";
import { DEFAULT_TOAST_TIME } from "configuration";
import { LoaderBasic } from "components/LoaderBasic";

export const RenewEmail = ({ user, confirmEmail, globalEffectUpdate }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(user.email);
  const [emailData] = useState({
    label: "Tu correo es:",
    value: email,
    id: "email",
  });

  const onClickNo = () => {
    setIsDisabled(!isDisabled);
  };

  const toast = useToast();

  const onClickYes = async () => {
    const { data, status } = await confirmEmail({ email: email });
    if (data.status) {
      globalEffectUpdate();
    } else if (data.error_code === 1) {
      toast({
        position: "top",
        title: "Correo ya existente",
        description: "Ingresa un correo diferente",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (status === 422) {
      toast({
        title: "Email con formato incorrecto",
        description: "Ingrese un correo válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
  };

  const onClick = async () => {
    setLoading(true);
    const { data, status } = await confirmEmail({ email: email });
    if (data.status) {
      globalEffectUpdate();
    } else if (data.error_code === 1) {
      toast({
        position: "top",
        title: "Correo ya existente",
        description: "Ingresa un correo diferente",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (status === 422) {
      toast({
        title: "Email con formato incorrecto",
        description: "Ingrese un correo válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  if (loading) return <LoaderBasic />;

  return (
    <Flex p={8} flex={1} align={"center"} justify={"center"}>
      <Stack spacing={4} w={"full"} maxW={"md"}>
        <Heading
          color={"blue.700"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          mb="2%"
        >
          Confirma que tu correo sea correcto
        </Heading>
        <FormFieldValidation
          fieldData={emailData}
          isDisabled={isDisabled}
          setValue={setEmail}
        />
        {(isDisabled &&
          ((loading && (
            <CountdownLoader onCountdownEnd={() => setLoading} />
          )) || (
            <ButtonValidations onClickYes={onClickYes} onClickNo={onClickNo} />
          ))) ||
          (loading && (
            <CountdownLoader onCountdownEnd={() => setLoading} />
          )) || (
            <Button
              colorScheme="blue"
              onClick={onClick}
              isLoading={loading}
              loadingText="Enviando"
            >
              Enviar
            </Button>
          )}
      </Stack>
    </Flex>
  );
};
