import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import React from "react";

function RecuperarPassPage({ onResetPassword }) {
  const { register, handleSubmit } = useForm();

  return (
    <Flex minH={"90vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={"white"}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
          ¿Olvidate tu contraseña?
        </Heading>
        <Text fontSize={{ base: "sm", sm: "md" }} color={"gray.800"}>
          Recibirás un correo con la liga de restablecimiento
        </Text>
        <form onSubmit={handleSubmit(onResetPassword)}>
          <FormControl id="email">
            <Input
              {...register("email")}
              placeholder="tu-correo@ejemplo.com"
              _placeholder={{ color: "gray.500" }}
              type="email"
            />
          </FormControl>
          <Stack spacing={6}>
            <Button
              type="submit"
              mt={2}
              bg={"blue.400"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
            >
              Enviar
            </Button>
          </Stack>
        </form>
      </Stack>
    </Flex>
  );
}

export default RecuperarPassPage;
