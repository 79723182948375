import { useEffect, useState } from "react";
import MoneyOnTheWay from "pages/user/MoneyOnTheWay";
import MiCredito from "components/nuovo/MiCredito";
import redux from "reducers/utils/Redux";
import { LoaderBasic } from "components/LoaderBasic";
import React from "react";

function CreditoPage({ getMyCredit, globalEffectUpdate, creditRenewal }) {
  const [payments, setPayments] = useState([]);
  const [credit, setCredit] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let { data, status } = await getMyCredit();
      if (data && status === 200) {
        setCredit(data);
        const unOrderedPayments = data.payments;
        const ordererPayments = unOrderedPayments.sort((a, b) => {
          if (a.payment_date > b.payment_date) {
            return 1;
          }
          if (a.payment_date < b.payment_date) {
            return -1;
          }
          return 0;
        });
        setPayments(ordererPayments);
      }
    }
    fetchData();
  }, [getMyCredit]);

  if (credit === null) {
    return <LoaderBasic />;
  }

  if (!credit.has_been_withdrawn) {
    return <MoneyOnTheWay />;
  }

  // Utilizando la payment_date buscar que dia de la semana es
  // y mostrarlo en el componente

  const tasaInteres = (0.8966 / 52) * 12;
  let today = new Date();

  const next_payment = payments?.find(
    (element) => Date.parse(element?.payment_date) >= today
  );
  const date = new Date(next_payment?.payment_date);

  const nextPaymentDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  const interesIVA = credit?.amount * tasaInteres * 0.16;

  const pagados = payments?.filter((pago) => pago.is_paid).length;
  const { is_paid } = credit;
  return (
    <MiCredito
      globalEffectUpdate={globalEffectUpdate}
      nextPaymentDate={nextPaymentDate}
      interesIVA={interesIVA}
      credit={credit}
      pagados={pagados}
      tasaInteres={tasaInteres}
      misPagos={payments}
      payment_day={credit.day_payment}
      is_paid={is_paid}
      creditRenewal={creditRenewal}
    />
  );
}

export default redux(CreditoPage);
