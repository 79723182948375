import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React  from 'react';

export const FormFieldValidation = ({
  fieldData,
  isDisabled = true,
  setValue,
}) => {
  const { label, value, id, placeholder } = fieldData;
  return (
    <FormControl>
      <FormLabel htmlFor={id} fontSize={"small"} fontWeight={"semibold"}>
        {label}
      </FormLabel>
      <Input
        id={id}
        placeholder={placeholder}
        size="sm"
        rounded="md"
        isDisabled={isDisabled}
        defaultValue={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </FormControl>
  );
};
