import { Box, Heading, Input, Text, useToast } from "@chakra-ui/react";
import { ButtonValidations } from "./ButtonValidations";
import { useEffect, useState } from "react";
import React from "react";
import { DEFAULT_TOAST_TIME } from "configuration";
import { LoaderBasic } from "components/LoaderBasic";

export const BankAcountConfirmation = ({
  globalEffectUpdate,
  confirmBankData,
  getBankData,
}) => {
  const toast = useToast();
  const [bankData, setBankData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!getBankData) return;
      const { data, status } = await getBankData();
      if (status === 200) setBankData(data);
      else
        toast({
          position: "top",
          title: "Estamos experimentando intermitencia",
          description: "Inténtalo de nuevo mas tarde",
          status: "error",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
    };
    fetchData();
  }, [getBankData, toast, setBankData]);

  const { number, bank } = bankData;

  const onCLickYes = () => {
    setLoading(true);
    const { status } = confirmBankData({ confirm: true });
    if (status === 200) {
      globalEffectUpdate();
    } else {
      toast({
        position: "top",
        title: "Estamos experimentando intermitencia",
        description: "Inténtalo de nuevo mas tarde",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  const onClickNo = () => {
    setLoading(true);
    const { status } = confirmBankData({ confirm: false });
    if (status === 200) {
      globalEffectUpdate();
    } else {
      toast({
        position: "top",
        title: "Estamos experimentando intermitencia",
        description: "Inténtalo de nuevo mas tarde",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  if (loading) {
    return <LoaderBasic />;
  }

  return (
    <Box textAlign="center" m="1%">
      <Heading
        color={"blue.700"}
        textAlign={"center"}
        fontWeight="normal"
        my="2%"
      >
        ¿Tu cuenta bancaria sigue siendo la misma?
      </Heading>
      <Text>
        Esta es tu cuenta bancaria que tenemos registrada. Si tus datos siguen
        siendo los mismos, presiona "Sí" para continuar con el proceso. En caso
        contrario, presiona "No" para actualizar tus datos.
      </Text>
      <Input
        my={2}
        placeholder="Bank account number"
        size="sm"
        rounded="md"
        disabled={true}
        defaultValue={number}
      />
      <Input
        my={2}
        placeholder="Bank"
        defaultValue={bank}
        size="sm"
        rounded="md"
        disabled={true}
      />
      <ButtonValidations onClickYes={onCLickYes} onClickNo={onClickNo} />
    </Box>
  );
};
