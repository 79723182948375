import { Flex, Stack, useToast, Heading } from "@chakra-ui/react";
import redux from "reducers/utils/Redux";
import { DEFAULT_TOAST_TIME } from "configuration";
import { RequestAmountForm } from "components/user/RequestAmountForm";
import { useState } from "react";
import React from "react";

function RequestAmount({ user, requestAmount, globalEffectUpdate }) {
  const today = new Date();
  const [buttonLoader, setButtonLoader] = useState(false);
  const toast = useToast();

  const onSubmit = async (dataForm) => {
    setButtonLoader(true);
    const body = {
      cliente: user,
      fecha: today,
      plazo: 12,
      monto: dataForm.monto,
      dia_pago: today.getDay(),
    };
    let { data, status } = await requestAmount(body);
    if (status === 200) {
      toast({
        title: "Monto solicitado",
        position: "top",
        description: "Tu solicitud ha sido enviada",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setButtonLoader(false);
      globalEffectUpdate();
    } else if (data.error_code === 3) {
      toast({
        title: "Cantidad no válida",
        position: "top",
        description: "Ingrese un monto entre 2000 y 3000",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setButtonLoader(false);
    } else if (status === 422) {
      toast({
        title: "Monto con formato incorrecto",
        position: "top",
        description: "Ingrese un monto válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setButtonLoader(false);
    }
  };
  return (
    <>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={4} w={"full"} maxW={"md"}>
          <Heading
            color={"blue.700"}
            w="100%"
            textAlign={"center"}
            fontWeight="normal"
            mb="2%"
          >
            Confirma el monto para tu crédito
          </Heading>
          <RequestAmountForm onSubmit={onSubmit} buttonLoader={buttonLoader} />
        </Stack>
      </Flex>
    </>
  );
}

export default redux(RequestAmount);
