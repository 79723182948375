import axios from "axios";

import { API_URL } from "configuration";

class Actions {
  constructor() {
    this.state = {};
  }

  getHeader = (body) => {
    let headers = {
      Accept: "application/json",
    };
    let auth = localStorage.getItem("access");
    if (auth) {
      headers["Authorization"] = `JWT ${auth}`;
    }
    if (body instanceof FormData) {
      headers["Content-Type"] = "multipart/form-data";
    } else {
      headers["Content-Type"] = "application/json; charset=UTF-8";
    }
    return headers;
  };

  get = (url, type) => {
    return async (dispatch) => {
      const config = {
        headers: this.getHeader(null),
      };
      try {
        const res = await axios.get(`${API_URL}${url}`, config);
        dispatch({
          type: type,
          payload: res,
        });
        return {
          data: res.data,
          status: res.status,
        };
      } catch (error) {
        return {
          data: error.response.data,
          status: error.status,
        };
      }
    };
  };

  post = (url, body, type) => {
    return async (dispatch) => {
      const config = {
        headers: this.getHeader(body),
      };
      try {
        const res = await axios.post(`${API_URL}${url}`, body, config);
        dispatch({
          type: type,
          payload: res.data,
        });

        return {
          data: res.data,
          status: res.status,
        };
      } catch (error) {
        return {
          data: error.response.data,
          status: error.status,
        };
      }
    };
  };

  patch = (url, type) => {
    return async (dispatch) => {
      const config = {
        headers: this.getHeader(null),
      };
      try {
        const res = await axios.patch(`${API_URL}${url}`, {}, config);
        dispatch({
          type: type,
          payload: res,
        });
        return {
          data: res.data,
          status: res.status,
        };
      } catch (error) {
        return {
          data: error.response.data,
          status: error.status,
        };
      }
    };
  };

  put = (url, body, type) => {
    return async (dispatch) => {
      const config = {
        headers: this.getHeader(body),
      };
      try {
        const res = await axios.put(`${API_URL}${url}`, body, config);
        dispatch({
          type: type,
          payload: res,
        });
        return {
          data: res.data,
          status: res.status,
        };
      } catch (error) {
        return {
          data: error.response.data,
          status: error.status,
        };
      }
    };
  };
}

export default Actions;
