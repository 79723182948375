import {
    Flex,
    Button,
    Container,
    Heading,
    Image,
    Grid,
    Text,
  } from "@chakra-ui/react";
  import imagen from "assets/img/imagen.webp";
  import React  from 'react';

  function RequestOtherCredit({ updateStep }) {
    return (
      <>
        <Grid
          mb={10}
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          align={"center"}
          gap={{ base: "8", md: "5" }}
        >
          <Container maxW={"90vw"}>
            <Heading
              color={"blue.700"}
              w="100%"
              textAlign={"center"}
              fontWeight="normal"
              my="5%"
            >
              No tienes un crédito vigente actualmente
            </Heading>
  
            <Flex justify={"center"}>
              <Text
                mt="2%"
                fontSize="16px"
                fontFamily="Arial, sans-serif"
                // fontWeight="bold"
                textAlign="justify"
                maxWidth="85%"
              >
                🌟 ¡Vuelve a experimentar la facilidad y conveniencia de nuestros
                microprestamos!
                <br />
                En Bund, valoramos tu confianza en nosotros y sabemos que en
                ocasiones las necesidades financieras pueden surgir en el momento
                menos esperado. Estamos aquí para ofrecerte una solución confiable
                y asequible.
                <br />
                💡 ¿Por qué volver a elegirnos?
                <br />
                ✅ Tasa de interés baja
                <br />
                ✅ Sin cargos por intereses moratorios
                <br />
                ✅ Proceso sencillo y rápido
                <br />
                ✅ Experiencia satisfactoria
                <br />
                💬 ¡No pierdas esta oportunidad de volver a experimentar la
                comodidad y confianza que ofrecemos en Bund!
              </Text>
            </Flex>
  
            <Flex
              mt={"2%"}
              justifyContent={"center"}
              color={"blue.700"}
              w="100%"
              my="5%"
            >
              <Button onClick={updateStep} colorScheme={"blue"}>
                Renueva tu crédito
              </Button>
            </Flex>
          </Container>
  
          <Flex flex={1} align={"center"}>
            <Image
              alt={"Login Image"}
              objectFit={"cover"}
              src={imagen}
              h={{ base: "50vh", sm: "60vh", md: "70vh", lg: "80vh" }}
            />
          </Flex>
        </Grid>
      </>
    );
  }
  
  export default RequestOtherCredit;
  