import {
  FormControl,
  FormLabel,
  Button,
  Center,
  useToast,
  Heading,
  Text,
  HStack,
  PinInput,
  PinInputField,
  Box,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { DEFAULT_TOAST_TIME } from "configuration";
import CountdownLoader from "components/CountdownLoader";
import React from "react";
import { LoaderBasic } from "components/LoaderBasic";

export const EmailVerification = ({
  sendVerificationEmailCode,
  verifyVerificationEmailCode,
  globalEffectUpdate,
}) => {
  const { register, handleSubmit } = useForm();
  const [btnIsDisabled, setBtnIsDisabled] = useState(true);
  const [emailCode, setEmailCode] = useState("");
  const [buttonVisible, setButtonVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  let validate = null;

  const handleCountdownEnd = () => {
    setButtonVisible(true);
  };

  const toast = useToast();
  const onSubmit = async () => {
    setLoading(true);
    const { data } = await verifyVerificationEmailCode(emailCode);
    setLoading(false);
    if (data.valid) {
      toast({
        title: "Se ha verificado tu correo electrónico",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      globalEffectUpdate();
    } else {
      toast({
        title: "El código de verificación es incorrecto",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
  };

  const onComplete = (value) => {
    setBtnIsDisabled(false);
    setEmailCode(value);
  };

  const handleClick = async () => {
    const { data } = await sendVerificationEmailCode();
    validate = data.valid_until;
    if (validate !== null) {
      setButtonVisible(false);
      toast({
        title: "Se ha enviado un correo con tu código de verificación",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else {
      toast({
        title: "El código de verificación ha caducado",
        description: "Da click en reenviar para recibir un nuevo código",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return <LoaderBasic />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Center>
          <Heading
            color={"blue.700"}
            w="90%"
            textAlign={"center"}
            fontWeight="normal"
            mb="2%"
          >
            Por favor completa la verificación de tu correo electrónico
          </Heading>
        </Center>
        <div>
          <FormControl mt={"1%"}>
            <Center>
              <FormLabel maxW="60%" textAlign="center">
                Introduce el código de verificación que te enviamos a tu correo,
                verifica en tu bandeja de entrada o de spam
              </FormLabel>
            </Center>
            <HStack my={3} justifyContent="center" {...register("emailPIN")}>
              <PinInput
                id="emailPIN"
                autoFocus={true}
                onComplete={onComplete}
                required
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </FormControl>
          {btnIsDisabled && (
            <Center>
              <Text maxW="50%" align={"center"} fontSize="sm" color={"tomato"}>
                El botón de validar se habilitará una vez que ingreses el código
                de verificación
              </Text>
            </Center>
          )}
          <Box mt="5" mx="5">
            <Center>
              {buttonVisible ? (
                <Button
                  mt={2}
                  mr={10}
                  colorScheme={"blue"}
                  variant={"solid"}
                  id="sendEmail"
                  onClick={handleClick}
                >
                  Reenviar email
                </Button>
              ) : (
                <CountdownLoader onCountdownEnd={handleCountdownEnd} />
              )}

              {btnIsDisabled || (
                <Button
                  mt={2}
                  isDisabled={btnIsDisabled}
                  colorScheme={"blue"}
                  variant={"solid"}
                  id="validateEmail"
                  type="submit"
                >
                  Validar email
                </Button>
              )}
            </Center>
          </Box>
        </div>
      </div>
    </form>
  );
};
