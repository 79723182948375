import {
  Container,
  Flex,
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Grid,
  Box,
  Center,
  useToast,
} from "@chakra-ui/react";

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import RenewCreditModal from "./RenewCreditModal";
import { forEach } from "lodash";
import React from "react";
import { DEFAULT_TOAST_TIME } from "configuration";

export const days = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sabado",
  "Domingo",
];

function MiCredito({
  credit,
  pagados,
  tasaInteres,
  nextPaymentDate,
  misPagos,
  interesIVA,
  payment_day,
  globalEffectUpdate,
  is_paid,
  creditRenewal,
}) {
  let today = new Date();
  const toast = useToast;
  async function onClose() {
    const { status } = await creditRenewal(true);
    if (status) {
      globalEffectUpdate();
    } else {
      toast({
        position: "top",
        title: "Estamos experimentando intermitencia",
        description: "Inténtalo de nuevo mas tarde",
        status: "info",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
  }

  async function onCloseWhithoutRenew() {
    const { status } = await creditRenewal(false);
    if (status) {
      globalEffectUpdate();
    } else {
      toast({
        position: "top",
        title: "Estamos experimentando intermitencia",
        description: "Inténtalo de nuevo mas tarde",
        status: "info",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
  }
  let sinPagar = 0;

  const pagoSemanal = (
    (credit?.amount * tasaInteres + interesIVA + credit?.amount) /
    12
  ).toFixed(2);
  forEach(misPagos, (pago) => {
    if (!pago.is_paid) {
      sinPagar += pago.amount - pago.amount_paid;
    }
  });

  let paymentStatus = (payment) => {
    let missing_amount = payment.amount - payment.amount_paid;
    const date = new Date(payment.payment_date);
    if (payment.is_paid) return <AiFillCheckCircle size={30} color="green" />;
    if (date <= today) {
      return <AiFillCloseCircle size={30} color="red" />;
    }
    if (missing_amount > 0) {
      return <AiFillCheckCircle size={30} color="yellow" />;
    }
  };

  return (
    <>
      <Center>
        <RenewCreditModal
          isOpen={is_paid}
          onClose={onClose}
          onCloseWhithoutRenew={onCloseWhithoutRenew}
        />
      </Center>
      <Grid
        mb={10}
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        align={"center"}
        gap={{ base: "8", md: "5" }}
      >
        <Container maxW={"90vw"}>
          <Heading
            color={"blue.700"}
            w="100%"
            textAlign={"center"}
            fontWeight="normal"
            my="5%"
          >
            Mi crédito
          </Heading>

          <Box color="red"></Box>

          <Flex justify={"space-between"}>
            <Heading my="2%" fontWeight="normal" size={"md"}>
              Mi préstamo
            </Heading>
            <Heading color={"blue.700"} my="2%" fontWeight="normal" size={"md"}>
              ${credit?.amount}
            </Heading>
          </Flex>
          <Flex justify={"space-between"}>
            <Text>Pagos realizados</Text>
            <Text>{pagados}/12</Text>
          </Flex>
          <Flex justify={"space-between"}>
            <Text mt="2%">Mis días de pago</Text>
            <Text mt="2%">{days[payment_day]}</Text>
          </Flex>
          <Flex justify={"space-between"}>
            <Text my="2%">Mi pago semanal</Text>
            <Text my="2%">${pagoSemanal}</Text>
          </Flex>
          <Flex justify={"space-between"}>
            <Text>Fecha de próximo pago</Text>
            <Text>{nextPaymentDate}</Text>
          </Flex>
          <Flex justify={"space-between"}>
            <Text>Saldo remanente</Text>
            <Text>${sinPagar.toFixed(2)}</Text>
          </Flex>
        </Container>

        <Container maxW={"90vw"}>
          <Heading
            color={"blue.700"}
            w="100%"
            textAlign={"center"}
            fontWeight="normal"
            my="5%"
          >
            Mis pagos
          </Heading>
          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th
                    style={{
                      maxWidth: "100px",
                      padding: "6px",
                      fontSize: "12px",
                    }}
                  >
                    Pago
                  </Th>
                  <Th
                    style={{
                      maxWidth: "100px",
                      padding: "6px",
                      fontSize: "12px",
                    }}
                  >
                    Cantidad
                  </Th>
                  <Th
                    style={{
                      maxWidth: "100px",
                      padding: "6px",
                      fontSize: "12px",
                    }}
                  >
                    Pendiente
                  </Th>
                  <Th
                    style={{
                      maxWidth: "100px",
                      padding: "6px",
                      fontSize: "12px",
                    }}
                  >
                    Fecha
                  </Th>
                  <Th
                    style={{
                      maxWidth: "100px",
                      padding: "6px",
                      fontSize: "12px",
                    }}
                  >
                    Estatus
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {misPagos?.map(function (pago) {
                  const date = new Date(pago.payment_date);

                  return (
                    <Tr key={pago.num_payment}>
                      <Td
                        style={{
                          maxWidth: "200px",
                          padding: "8px",
                          fontSize: "14px",
                        }}
                      >
                        {pago.num_payment}
                      </Td>
                      <Td
                        style={{
                          maxWidth: "200px",
                          padding: "8px",
                          fontSize: "14px",
                        }}
                      >
                        ${pago.amount}
                      </Td>
                      <Td
                        style={{
                          maxWidth: "200px",
                          padding: "8px",
                          fontSize: "14px",
                        }}
                      >
                        ${(pago.amount - pago.amount_paid).toFixed(2)}
                      </Td>
                      <Td
                        style={{
                          maxWidth: "200px",
                          padding: "8px",
                          fontSize: "14px",
                        }}
                      >{`${date.getDate()}/${
                        date.getMonth() + 1
                      }/${date.getFullYear()}`}</Td>
                      <Td
                        style={{
                          maxWidth: "200px",
                          padding: "8px",
                          fontSize: "14px",
                        }}
                      >
                        {paymentStatus(pago)}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Container>
      </Grid>
    </>
  );
}

export default MiCredito;
