const API_URL = getApiUrl();
const ADMIN_TYPE = "1";
const CLIENT_TYPE = "2";
const DEFAULT_TOAST_TIME = 5000;
const ADMIN_HOME_ROUTE = "/admin";
const CLIENT_HOME_ROUTE = "/user";
const DEFAULT_EMAIL_PLACEHOLDER = "correo@dominio.com";
const DEFAULT_PASSWORD_PLACEHOLDER = "**********";

function getApiUrl() {
  let url = `${process.env.REACT_APP_BASE_URL}`;
  return process.env.REACT_APP_BASE_URL ? url : "http://10.1.0.110:81/";
}

const SITE_KEY = getSiteKey();

function getSiteKey() {
  let key = `${process.env.REACT_APP_SITE_KEY}`;
  return process.env.REACT_APP_SITE_KEY
    ? key
    : "6LfOXhkpAAAAAA67Zg6InuiPGdgmOeHSKq4qJHbw";
}

function getHeaders() {
  return {
    headers: {
      Authorization: `JWT ${localStorage.getItem("access")}`,
      "Content-Type": "application/json",
    },
  };
}

const BankDictionary = {
  "138": "ABC CAPITAL", "062": "AFIRME", "042": "MIFEL", "133": "BANCO ACTINVER",
  "128": "BANCO AUTOFIN MEXICO", "127": "BANCO AZTECA", "152": "BM BANCREA", "145": "BANCO BASE SA",
  "130": "BANCO COMPARTAMOS", "126": "CREDIT SUISSE", "030": "BAJIO", "149": "FORJADORES", "036": "BANCO INBURSA",
  "150": "BANCO INMOBILIARIO", "059": "BANCO INVEX", "110": "JP MORGAN", "021": "BANCO HSBC", "112": "BANCO MONEX",
  "132": "BANCO MULTIVA", "148": "BANCO PAGATODO", "058": "BANCO REGIONAL DE MONTERREY",
  "160": "Banco S3 Caceis México", "156": "BANCO SABADELL",
  "014": "BANCO SANTANDER", "157": "Banco Shinhan de México", "113": "BANCO VE POR MAS SA",  "137": "BANCOPPEL",
  "106": "BANK OF AMERICA", "159": "BANK OF CHINA", "147": "BANKAOOL", "072": "BANORTE IXE", "060": "BANSI",
  "129": "BANCO BARCLAYS", "012": "BBVA BANCOMER",  "109": "BNP",
  "140": "CONSUBANCO", "151": "DONDE BANCO", "155": "ICBC", "136": "INTERCAM BANCO",
  "158": "MIZUHO BANK", "044": "SCOTIABANK", "002": "BANAMEX"
}

const StepDictionary = {
  LOCKED: "0", //? Usuario bloqueado
  CONFIRM_EMAIL: "1", //? Confirmar correo
  OCR_INE: "2", //? Registro de INE mediante las imagenes
  INE_MANUAL: "2.5", //? Registro de INE manual
  SAVE_ADDRESS: "3", //? Registro de domicilio
  PARAMETRIC_MODEL: "3.5", //? Modelo parametrico
  BANK_DATA: "4", //? Registro de datos bancarios
  ENROLLED_PHONE: "5", //? Enrolamiento de telefono
  PENDING_PHONE_ENROLLMENT: "5.5", //? Enrolamiento de telefono pendiente
  CREATE_LOAD: "6", //? Creacion de solicitud de credito
  SIGN_CONTRACT: "7", //? Firma de contrato
  SEE_CREDIT: "8", //? Ver credito
  RENEW_CREDIT: "9", //? Renovar credito
  NO_RENEWAL_LOAN: "9.5",
  REFUSED: "10", //? Rechazado
  VALIDATE_PHONE: "11", //? Validar telefono
  VERIFY_RENEWAL_INFORMATION: "12", //? Verificar informacion de renovacion
  CONFIRM_OCR_INFO: "13", //? Verificar datos del OCR
  VERIFY_RENEWAL_EMAIL_INFORMATION: "12",
  VERIFY_RENEWAL_PHONE_INFORMATION: "12.5",
  CAN_NOT_RENEW_LOAN: "14",
  VALIDATE_CODE_TO_RENEW: "15",
  INE_MANUAL_RENEWAL: "16",
  SAVE_ADDRESS_RENEWAL: "17",
  NUOVO_RENEWAL: "18",
  REFERENCE_NUOVO: "19",
  CONFIRM_BANK_DATA: "20",
};

const DEFAULT_INTERVAL = 1000;
const INTERVAL_INCREASE_STEP = 100;
const MAX_INTERVAL_1_MIN = 60000;
const MAX_INTERVAL_1_HOUR = 3600000;
const MAX_INTERVAL_24_HOURS = 86400000;

function IsCardNumber(cardNo) {
  let nDigits = cardNo.length;

  let nSum = 0;
  let isSecond = false;
  for (let i = nDigits - 1; i >= 0; i--) {
    let d = cardNo[i].charCodeAt() - "0".charCodeAt();

    if (isSecond) d = d * 2;

    // We add two digits to handle
    // cases that make two digits
    // after doubling
    nSum += parseInt(d / 10, 10);
    nSum += d % 10;

    isSecond = !isSecond;
  }
  return nSum % 10 === 0;
}

export {
  API_URL,
  ADMIN_TYPE,
  CLIENT_TYPE,
  DEFAULT_TOAST_TIME,
  ADMIN_HOME_ROUTE,
  CLIENT_HOME_ROUTE,
  DEFAULT_EMAIL_PLACEHOLDER,
  DEFAULT_PASSWORD_PLACEHOLDER,
  SITE_KEY,
  IsCardNumber,
  BankDictionary,
  StepDictionary,
  getHeaders,
  DEFAULT_INTERVAL,
  INTERVAL_INCREASE_STEP,
  MAX_INTERVAL_1_MIN,
  MAX_INTERVAL_1_HOUR,
  MAX_INTERVAL_24_HOURS,
};
